<template>
  <div
    class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded"
  >
    <div class="relative flex-1 overflow-y-scroll">
      <PLoader v-if="!loadedOnce" />

      <table v-else class="data-table">
        <thead>
          <tr>
            <th
              class="w-24 text-center sortable"
              :class="{ sorted: sortField === 'id' }"
            >
              <PSortHeader
                field="id"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.Id')"></span>
              </PSortHeader>
            </th>
            <th>
              <div
                class="p-4 text-left"
                v-html="$tk('Common.General.Topic')"
              ></div>
            </th>
            <th
              class="w-40 text-center sortable"
              :class="{ sorted: sortField === 'createdLocal' }"
            >
              <PSortHeader
                field="createdLocal"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.Created')"></span>
              </PSortHeader>
            </th>
            <th
              class="w-40 text-center sortable"
              :class="{ sorted: sortField === 'modifiedLocal' }"
            >
              <PSortHeader
                field="modifiedLocal"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.LastActivity')"></span>
              </PSortHeader>
            </th>
            <th
              class="text-left"
              :class="{ sorted: sortField === 'customerName' }"
              v-if="isGlobalAdmin"
            >
              <PSortHeader
                field="customerName"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.Customer')"></span>
              </PSortHeader>
            </th>
            <th
              class="text-left"
              :class="{ sorted: sortField === 'locationName' }"
              v-if="isGlobalAdmin || isCustomerAdmin"
            >
              <PSortHeader
                field="locationName"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.Location')"></span>
              </PSortHeader>
            </th>
            <th
              class="text-left"
              :class="{ sorted: sortField === 'orderedBy.name' }"
            >
              <PSortHeader
                field="orderedBy.name"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.CreatedBy')"></span>
              </PSortHeader>
            </th>
            <th
              class="text-left"
              :class="{ sorted: sortField === 'employee.name' }"
            >
              <PSortHeader
                field="employee.name"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.Responsible')"></span>
              </PSortHeader>
            </th>
            <th
              v-if="location.isSmartRetur"
              class="text-center w-28 sortable"
              :class="{ sorted: sortField === 'priorityId' }"
            >
              <PSortHeader
                field="priorityId"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.Priority')"></span>
              </PSortHeader>
            </th>
            <th class="w-12"></th>
            <th
              class="w-32 text-center sortable"
              :class="{ sorted: sortField === 'statusId' }"
            >
              <PSortHeader
                field="statusId"
                :sortField="sortField"
                :sortDescending="sortDescending"
                @sort="onSort"
              >
                <span v-html="$tk('Common.General.Status')"></span>
              </PSortHeader>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(ticket, index) in ticketsSorted"
            :key="index"
            :class="{ odd: index % 2 !== 0 }"
            @click="onSelect(ticket.id)"
          >
            <td class="text-center">
              {{ ticket.id }}
            </td>
            <td>
              {{ ticket.title }}
            </td>
            <td class="text-center">
              {{ ticket.createdLocal | dateAndTime }}
            </td>
            <td class="text-center">
              {{ ticket.modifiedLocal | dateAndTime }}
            </td>
            <td v-if="isGlobalAdmin">
              <PDisplayLocation
                :item="ticket"
                :config="{ textProperty: 'customerName' }"
              />
            </td>
            <td v-if="isGlobalAdmin || isCustomerAdmin">
              {{ ticket.locationName }}
            </td>
            <td>
              {{ ticket.orderedBy ? ticket.orderedBy.name : "" }}
            </td>
            <td>
              {{ ticket.employee ? ticket.employee.name : "" }}
            </td>
            <td
              class="text-center"
              :class="{
                'text-red-500': ticket.priority === 'High',
              }"
              v-if="location.isSmartRetur"
              v-html="
                ticket.priority === 'Low'
                  ? $tk('Common.SupportPriority.Low')
                  : ticket.priority === 'High'
                  ? $tk('Common.SupportPriority.High')
                  : ''
              "
            ></td>
            <td class="text-center">
              <FontAwesomeIcon
                class="text-base text-red-500"
                v-if="ticket.attention"
                :icon="['far', 'exclamation-circle']"
              />
            </td>
            <td class="text-center">
              <PStatusLabel :status="ticket.status" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="filters">
      <div
        class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
      >
        <PInput
          :placeholder="$tk('SupportTickets.SearchPlaceholder', true)"
          :clearable="true"
          iconInsideLeft="search"
          v-model="term"
          :wait="300"
        />

        <PSelect
          class="w-full md:w-64"
          :placeholder="$tk('Common.General.Customer')"
          :items="customers"
          :searchable="true"
          :clearable="true"
          itemText="name"
          itemValue="id"
          v-model="selectedCustomerId"
          v-if="customers && customers.length > 1"
        />

        <PSelect
          class="w-full md:w-56"
          :placeholder="$tk('Common.General.Location')"
          :items="locations"
          :searchable="true"
          :clearable="true"
          itemText="name"
          itemValue="id"
          v-model="selectedLocationId"
          v-if="locations && locations.length > 1"
        />

        <div class="flex items-center space-x-4">
          <PSelect
            class="w-32"
            xlabel="$tk('Common.General.Status')"
            :items="statuses"
            v-model="selectedStatus"
          />

          <div class="flex flex-col items-center px-2">
            <PToggle
              :stacked="true"
              :label="$tk('SupportTickets.MyTickets')"
              v-model="onlyForMe"
            />
          </div>
        </div>
      </div>
    </portal>

    <portal to="actions">
      <PButton
        @click="onCreateShow"
        @shortkey.native="onCreateShow"
        color="primary"
        v-hint="{ show: hintsVisible, text: 'N' }"
        v-shortkey.push="['alt', 'n']"
        >{{ $tk("SupportTickets.NewTicket") }}</PButton
      >
    </portal>

    <CreateDialog v-if="createDialogVisible" @close="onCloseCreateDialog" />

    <TicketDialog
      v-if="selectedId"
      :id="selectedId"
      @close="onCloseTicketDialog"
    />
  </div>
</template>

<script>
import http from "@/utilities/http";
import CreateDialog from "../dialogs/CreateDialog.vue";
import TicketDialog from "../dialogs/TicketDialog.vue";
import PStatusLabel from "../components/PStatusLabel.vue";
import PSortHeader from "../components/PSortHeader.vue";
import { mapGetters } from "vuex";
import { orderBy } from "lodash";

export default {
  components: {
    CreateDialog,
    TicketDialog,
    PStatusLabel,
    PSortHeader,
  },

  props: {
    id: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      customers: [],
      locations: [],
      tickets: [],
      statuses: [
        { value: "0,1,2,3", text: this.$tk("Common.General.All") },
        { value: "0", text: this.$tk("Common.SupportStatuses.New") },
        { value: "1", text: this.$tk("Common.SupportStatuses.Open") },
        { value: "2", text: this.$tk("Common.SupportStatuses.Pending") },
        { value: "3", text: this.$tk("Common.SupportStatuses.Closed") },
        { value: "4", text: this.$tk("Common.SupportStatuses.Archived") },
      ],
      sortField: "statusId",
      sortDescending: false,
      term: "",
      selectedCustomerId: "",
      selectedLocationId: "",
      selectedStatus: "0,1,2,3",
      onlyForMe: false,
      selectedId: "",
      loadedOnce: false,
      createDialogVisible: false,
      reloadTicketsTimer: null,
    };
  },

  computed: {
    ...mapGetters(["customer", "location", "user", "hintsVisible"]),

    ticketsSorted() {
      let sortFields = [this.sortField];
      let sortDirections = [this.sortDescending ? "desc" : "asc"];

      if (this.sortField !== "statusId") {
        sortFields.unshift("statusId");
        sortDirections.unshift("asc");
      }

      return orderBy(this.tickets, sortFields, sortDirections);
    },

    email() {
      return this.onlyForMe ? this.user.email : "";
    },

    isGlobalAdmin() {
      return this.user.isGlobalAdmin;
    },

    isCustomerAdmin() {
      return !this.isGlobalAdmin && this.user.isCustomerAdmin;
    },

    isRegularUser() {
      return !this.isGlobalAdmin && !this.user.isCustomerAdmin;
    },

    params() {
      let params = {
        status: this.selectedStatus,
      };

      if (this.term) {
        params.status = "0,1,2,3,4";
        params.term = this.term;
      }

      if (this.email) {
        params.email = this.email;
      }

      const customerId =
        this.selectedCustomerId ||
        (this.isCustomerAdmin ? this.customer.id : "");
      if (customerId) params.customerId = customerId;

      const locationId =
        this.selectedLocationId || (this.isRegularUser ? this.location.id : "");
      if (locationId) params.locationId = locationId;

      return params;
    },
  },

  methods: {
    onCreateShow() {
      this.createDialogVisible = true;
    },

    onSelect(id) {
      this.$router.replace({
        name: "support.Tickets",
        params: {
          id: `${id}`,
        },
      });
      this.selectedId = `${id}`;
    },

    async onSort(sort) {
      this.sortField = sort.field;
      this.sortDescending = sort.descending;
    },

    async onCloseCreateDialog() {
      this.createDialogVisible = false;
      await this.load();
    },

    async onCloseTicketDialog() {
      this.$router.replace({ name: "support.Tickets" });
      this.selectedId = "";
      await this.load();
    },

    async init() {
      if (this.user.isGlobalAdmin) {
        this.customers = await http.get("Customers");
      } else if (this.user.isCustomerAdmin) {
        this.locations = await http.get("Locations", {
          params: { customerId: this.customer.id },
        });
      }
    },

    async load() {
      try {
        this.tickets = await http.get("SupportTickets", {
          params: this.params,
        });
      } catch (error) {
        this.$notifyError("", error.reason);
      }

      this.loadedOnce = true;
    },
  },

  async created() {
    await Promise.all([this.init()]);

    if (this.$route.query.t || this.$route.query.i || this.$route.query.c) {
      this.createDialogVisible = true;
    }

    this.reloadTicketsTimer = setInterval(async () => {
      await this.load();
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.reloadTicketsTimer);
  },

  watch: {
    id: {
      handler: function (val) {
        this.selectedId = val;
      },
      immediate: true,
    },

    selectedCustomerId: async function (val) {
      this.selectedLocationId = "";

      if (val) {
        this.locations = await http.get("Locations", {
          params: { customerId: val },
        });
      } else {
        this.locations = [];
      }
    },

    params: {
      handler: async function () {
        await this.load();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
th {
  @apply leading-4 bg-gradient-to-b from-gray-500 to-gray-600;
}

th.sortable {
  @apply cursor-pointer;
}

th.sortable.sorted,
th.sortable:hover {
  @apply from-gray-400 to-gray-500;
}

tr {
  background-color: theme("colors.gray.50");
}

tr.odd {
  background-color: theme("colors.gray.100");
}

tr:hover {
  background-color: theme("colors.orange.200");
}

td {
  @apply px-4 py-2;
  border-bottom: 1px solid theme("colors.gray.300");
  border-right: 1px solid theme("colors.gray.300");
}

tbody tr {
  cursor: pointer;
}
</style>